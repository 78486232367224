<template>
    <div>
        <div class="default_box" v-if="preLoad">
            <div class="default_header">
                <h1 v-if="this_category && this_category.name" v-html="this_category.name"></h1>
                <h1 v-else>Справочник организаций</h1>
            </div>
            <SearchPlagin :cat_search="cat_search"></SearchPlagin>
            <b-row no-gutters>
                <b-col cols="12" md="6" v-for="(item, index) in list" :key="index" class="catalob_box">
                    <router-link :to="{name:(item.category_children_count>0)?'OrganisationCat':'OrganisationList' ,params: { id:item.id }}">
                        <div class="catalog_item">
                            <img :src="item.icon" alt="icon" class="icon" v-if="item.icon" />
                            <div v-html="item.name"></div>
                        </div>
                    </router-link>
                </b-col>
            </b-row>
            <AdsPlaginNew :direction="'horizontal'" class="mt-3" />
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-console */
import SearchPlagin from '@/components/Plagins/SearchPlagin';
export default {
    name: "CatalogCategory",
    components: {
        SearchPlagin
    },
    metaInfo() {
        return {
            title: this.this_category && this.this_category.name ? `${this.this_category.name} ` : (this.list && this.list.length ? `Справочник организаций ` : null),
            meta: [
                {name: 'description', itemprop: 'description', content: this.this_category && this.this_category.name ? `${this.this_category.name} в городе ${this.$myCity.name}. Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.` : `Справочник организаций в городе ${this.$myCity.name}: базы отдыха, туристические агентства, кафе, бары, рестораны, медицина, авто, недвижимость, торговля, производство, службы города` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: this.this_category && this.this_category.name ? `${this.this_category.name} ${this.$myCity.name}` : `Справочник организаций ${this.$myCity.name}`},
                {property: 'og:description', content: this.this_category && this.this_category.name ? `${this.this_category.name} в городе ${this.$myCity.name}. Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.` : `Справочник организаций в городе ${this.$myCity.name}: базы отдыха, туристические агентства, кафе, бары, рестораны, медицина, авто, недвижимость, торговля, производство, службы города`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            list: [],
            this_category: {},
            cat_search: 'Organizations',

        }
    },
    methods: {
        api() {
            this.preLoad = null;
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Organizations.getList';
            this.$http.get(url, {
                params: {
                    id: this.$route.params.id
                }
            }).then((response) => {
                this.list = Object.values(response.data.response.category_list).sort(function(a, b) {
                    if (a.sort < b.sort) {
                        return -1;
                    }
                    if (a.sort > b.sort) {
                        return 1;
                    }
                    return 0;
                });
                if(!this.list.length) this.$router.push({ name: 'NotFound' });
                this.this_category = response.data.response.this_category;
            }).finally(() => {
                this.preLoad = 'finish';

            })
        },
    },
    mounted() {
        this.api();

    },
    watch: {
        '$route.params.id': function() {
            this.api();
        },

    },

}
</script>
<style scoped>
@media only screen and (min-width: 992px) {
    .catalob_box:nth-child(odd) {
        padding-right: 5px !important;
    }

    .catalob_box:nth-child(even) {
        padding-left: 5px !important;
    }

    .catalog_item:hover {
        background: rgba(74, 118, 168, 0.1);
        transition: .2s;
    }

}

.icon {
    max-height: 30px;
    margin-right: 10px;
}

.catalob_box {
    padding-bottom: 10px !important;
    transition: .2s;
}


.catalog_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #fff;
    border-radius: 12px;
    height: 100%;
    color: #2c3e50;
}
</style>